import { render, staticRenderFns } from "./AdvertSection.vue?vue&type=template&id=48eb42be&scoped=true"
import script from "./AdvertSection.vue?vue&type=script&lang=js"
export * from "./AdvertSection.vue?vue&type=script&lang=js"
import style0 from "./AdvertSection.vue?vue&type=style&index=0&id=48eb42be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48eb42be",
  null
  
)

export default component.exports